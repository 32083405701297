<template>
  <section>
    <aom-password-prompt
      v-if="showPasswordPrompt"
      @passwordVerified="onSaveChanges"
      @passwordCancelled="onCancel" />
    <aom-skeleton-loader v-if="isLoading" />
    <b-form v-else class="mb-4">
      <b-card title="Profile Details">
        <b-row>
          <b-col sm="6">
            <validation-provider
              v-slot="validationContext"
              ref="profile_image"
              :rules="{ required: true }"
              vid="profile_image"
              name="Profile Image"
            >
              <b-form-group label-for="photo-input">
                <div class="photo-wrapper">
                  <fileUpload
                    v-model="show"
                    lang-type="en"
                    field="profile_img"
                    :width="80"
                    :height="80"
                    img-format="png"
                    :lang-ext="Object.assign({}, cropperSettings)"
                    @srcFileSet="cropSuccess"
                    @crop-success="cropSuccess"
                  />
                  <b-media>
                    <template #aside>
                      <b-avatar
                        v-if="!imgDataUrl"
                        variant="primary"
                        :text="userInitials"
                        :src="userProfileImage"
                        size="8rem"
                      />
                      <img
                        v-else
                        :src="imgDataUrl"
                        alt="profile_image"
                        style="width: 8rem"
                      />
                    </template>
                    <div class="media-body mt-75 ml-75">
                      <b-button
                        class="mr-2"
                        variant="primary"
                        @click="showCropperDialog"
                      >
                        <feather-icon icon="UploadIcon" class="mr-50" />
                        <span class="align-middle">Upload</span>
                      </b-button>
                      <p class="card-text">
                        <small
                          >Allowed JPG, GIF, BMP or PNG. Max size of 5MB</small
                        >
                      </p>
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </div>
                  </b-media>
                </div>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <hr class="my-2" />
        <b-form>
          <b-row>
            <b-col sm="6">
              <validation-provider
                v-slot="{ errors }"
                ref="firstName"
                :rules="{ required: true }"
                name="First name"
              >
                <b-form-group :label="$t('First name')" label-for="first-name">
                  <b-form-input
                    v-model="userEdit.first_name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('First name')"
                    name="first-name"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                v-slot="{ errors }"
                ref="lastName"
                rules="required"
                name="Last name"
              >
                <b-form-group :label="$t('Last name')" label-for="last-name">
                  <b-form-input
                    v-model="userEdit.last_name"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Last name')"
                    name="last-name"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="6">
              <validation-provider
                v-slot="{ errors }"
                ref="email"
                :rules="{ required: true }"
                name="Email"
              >
                <b-form-group :label="$t('Email')" label-for="email">
                  <b-form-input
                    v-model="userEdit.email"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Email')"
                    :disabled="isSamlSsoEnabled"
                    name="email"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="6">
              <validation-provider
                v-slot="validationContext"
                ref="phone"
                rules="required|aom_phone"
                name="Phone"
              >
                <b-form-group label="Phone" label-for="phone">
                  <aom-tel-input v-model="userEdit.phone" />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col sm="6">
              <b-form-group label-for="roles">
                <user-roles-select
                  v-model="userEdit.user_roles"
                  :list-type="userRolesListType"
                  :read-only="true"
                  @update="updateSelectedUserRole"
                />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <validation-provider ref="languages" name="languages">
                <b-form-group
                  label="Preferred Language"
                  label-for="selected-language"
                >
                  <v-select
                    v-model="userEdit.preferred_language"
                    :options="languageList"
                    multiple
                    label="name"
                    style="pointer-events: none"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <b-form-group
                v-slot="{ ariaDescribedby }"
                label="Email Notifications"
              >
                <b-form-checkbox
                  v-model="emailNotifications"
                  :aria-describedby="ariaDescribedby"
                  switch
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
      <!-- Integration -->
      <b-card v-if="showIntegrationsSection" title="Integrations">
        <b-row>
          <b-col v-if="isDisplayTeams" sm="4">
            <validation-provider
              v-slot="{ errors }"
              ref="teams"
              :rules="{ required: true }"
              name="Teams"
            >
              <b-form-group :label="$t('Teams')" label-for="teams">
                <template slot="label">
                  Teams
                  <feather-icon
                    v-b-tooltip.hover.top="'Teams Email'"
                    icon="AlertCircleIcon"
                    class="mr-50 my-icon"
                  />
                </template>
                <b-form-input
                  v-model="userEdit.teams"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Teams')"
                  name="teams"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col v-if="isDisplayZoom" sm="4">
            <b-form-group :label="$t('Zoom')" label-for="zoom">
              <template slot="label">
                Zoom
                <feather-icon
                  v-b-tooltip.hover.top="'Zoom Account ID'"
                  icon="AlertCircleIcon"
                  class="mr-50 my-icon"
                />
              </template>
              <b-link
                v-if="!userEdit.zoom"
                class="btn btn-primary btn-block"
                :href="zoomOAuthLink"
              >
                Connect
              </b-link>
              <b-button v-else variant="primary" block> Connected </b-button>
            </b-form-group>
          </b-col>
          <b-col v-if="isDisplaySkype" sm="4">
            <validation-provider
              v-slot="{ errors }"
              ref="skype"
              rules=""
              name="Skype ID"
            >
              <b-form-group :label="$t('Skype ID')" label-for="skype">
                <template slot="label">
                  Skype ID
                  <feather-icon
                    v-b-tooltip.hover.top="'Skype ID'"
                    icon="AlertCircleIcon"
                    class="mr-50 my-icon"
                  />
                </template>
                <b-form-input
                  v-model="userEdit.skype"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Skype ID')"
                  name="skype"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-card>

      <!-- Data & Account -->
      <b-card title="Data & Account">
        <div class="d-flex align-items-start">
          <b-button variant="primary" class="mr-1" @click="downLoadUserPDF">
            <feather-icon class="mr-1" icon="DownloadIcon" size="16" />
            <b-spinner v-if="isFetching" small />
            <span v-else>Download a copy of all my information</span>
          </b-button>
          <b-button
            class="mr-1"
            variant="primary"
            @click="terminateAccountAndLogout"
          >
            <feather-icon class="mr-1" icon="TrashIcon" size="16" />
            Terminate my account
          </b-button>
          <b-button
            v-if="canWithdrawMenteeApplication"
            class="mr-1"
            variant="primary"
            @click="withdrawApplication(userRoles.MENTEE)"
          >
            <feather-icon class="mr-1" icon="TrashIcon" size="16" />
            Withdraw {{ roleAlias(userRoles.MENTEE, program) }} application
          </b-button>
          <b-button
            class="mr-1"
            v-if="canWithdrawMentorApplication"
            variant="primary"
            @click="withdrawApplication(userRoles.MENTOR)"
          >
            <feather-icon class="mr-1" icon="TrashIcon" size="16" />

            Withdraw {{ roleAlias(userRoles.MENTOR, program) }} application
          </b-button>
          <b-button
            v-if="canApplyAsMentor"
            class="mr-1"
            variant="primary"
            @click="() => showModal"
          >
            <feather-icon class="mr-1" icon="UserIcon" size="16" />
            Join as a {{ roleAlias(userRoles.MENTOR, program) }}
          </b-button>
          <b-button
            v-if="canApplyAsMentee"
            class="mr-1"
            variant="primary"
            @click="addNewRole(userRoles.MENTEE)"
          >
            <feather-icon class="mr-1" icon="UserIcon" size="16" />
            Join as a {{ roleAlias(userRoles.MENTEE, program) }}
          </b-button>
        </div>
      </b-card>

      <div class="mt-2">
        <b-button
          variant="primary"
          class="mr-2"
          @click="onValidateUser"
        >
          <b-spinner
            v-if="isSubmitting"
            small
          />
          <span v-if="isSubmitting">Updating...</span>
          <span v-else>Submit</span>
        </b-button>
      </div>
    </b-form>
    <AOMWithdrawApplicationModal ref="withdraw-confirmation-modal" />
    <b-modal
      id="update-profile-modal"
      title="Update Mentor Role"
      v-model="showMentorMenteeModal"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <p>How many {{ menteeDisplay }}s would you like to {{ mentorDisplay }}?</p>    
      <v-select
        v-model="menteePerMentor"
        placeholder="Event"
        :options="menteePerMentorOptions"
        label="name"
      />
      <div class="d-flex justify-content-end mt-3">
        <b-button variant="outline-secondary" @click="showMentorMenteeModal = false">Cancel</b-button>
        <b-button variant="primary" class="ml-2" @click="() => addNewRole(userRoles.MENTOR)">
          Update
        </b-button>
      </div>
    </b-modal>

  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BMedia,
  BAvatar,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BSpinner,
  BLink,
  BModal,

  VBTooltip,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import UserRolesSelect from "@/@aom-core/UserRolesSelect.vue";
import {
  userRoles,
  userStatus,
  userStatusDisplay,
  integrations as integrationPlatforms,
  programTypes
} from "@/models";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import {
  getValidationState,
  getProgramsFromUserRoles,
  parseAbilitiesFromPermissions,
} from "@/libs/utils";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import fileUpload from "vue-image-crop-upload/upload-2.vue";
import { authService, usersService, applicationService } from "@services";
//eslint-disable-next-line
import { required } from "@validations";
import { mapMutations } from "vuex";
import AomSkeletonLoader from '@aom-core/AomSkeletonLoader.vue';
import AomPasswordPrompt from "@/@aom-core/AomPasswordPrompt.vue";
import {
  getAfterLogoutRoute,
  clearLocalStorage
} from "@/auth/utils";
import { roleAlias } from "@aom-core/utils/utils";
import AOMWithdrawApplicationModal from "@/@aom-core/AOMWithdrawApplicationModal.vue";
import { localeDateStringFromIsoDateTimeWithoutTimezone } from "@/libs/utils";
export default {
  name: "ParticipantProfile",
  components: {
    BRow,
    BCol,
    BCard,
    BMedia,
    BAvatar,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    UserRolesSelect,
    vSelect,
    BFormInvalidFeedback,
    BSpinner,
    BLink,
    fileUpload,
    AomSkeletonLoader,
    BFormCheckbox,
    BModal,
    AomPasswordPrompt,
    AOMWithdrawApplicationModal
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      isSubmitting: false,
      userRoles,
      userEdit: {},
      initialEditing: {},
      languageList: [],
      isFetching: false,
      cropperSettings: {
        preview: "Preview",
        btn: {
          off: "Cancel",
          close: "Close",
          back: "Back",
          save: "Save",
        },
        error: {
          onlyImg:
            "Please make sure your file is JPG, GIF, BMP or PNG and less than 5mb.",
          outOfSize:
            "Please make sure your file is JPG, GIF, BMP or PNG and less than ",
        },
      },
      show: false,
      imgDataUrl: "",
      isLoading: false,
      emailNotifications: true,
      showPasswordPrompt: false,
      password: '',
      newRole: null,
      menteePerMentor:1,
      showMentorMenteeModal: false,
    };
  },
  computed: {
    ...mapGetters("participants", ["applications", "matches"]),
    isTrainingProgram() {
      return this.program?.type_id === programTypes.TRAINING;
    },
    userRolesListType () {
      if (this.isTrainingProgram) {
        return 'trainee';
      }
      return 'participant';
    },
    userInitials() {
      const firstInt = Array.from(this.user?.first_name)[0];
      const lastInt = Array.from(this.user?.last_name)[0];
      return `${firstInt}${lastInt}`;
    },
    user() {
      return this.$store.getters["profile/profile"];
    },
    isSamlSsoEnabled() {
      return this.program !== null && this.program.program_saml.is_enabled;
    },
    canWithdrawMenteeApplication() {
      return (
        this.user.roles.includes(userRoles.MENTEE) &&
        this.program.withdraw_applications_enabled &&
        !this.applicationIsClosed(userRoles.MENTEE) && 
        this.user.user_roles.find(userRole => userRole.role_id === userRoles.MENTEE)?.completed_locked_and_active_matches_count <= 0
      );
    },
    canWithdrawMentorApplication() {
      return (
        this.user.roles.includes(userRoles.MENTOR) &&
        this.program.withdraw_applications_enabled &&
        !this.applicationIsClosed(userRoles.MENTOR) && 
        this.user.user_roles.find(userRole => userRole.role_id === userRoles.MENTOR)?.completed_locked_and_active_matches_count <= 0
      );
    },
    showModal() {
      this.showMentorMenteeModal = true;
      
    },
    userMenteeStatus() {
      return userStatusDisplay[userStatus.ACTIVE];
    },
    userMentorStatus() {
      return userStatusDisplay[userStatus.ACTIVE];
    },
    userIsMentee() {
      return this.user.roles.includes(userRoles.MENTEE);
    },
    userIsMentor() {
      return this.user.roles.includes(userRoles.MENTOR);
    },
    userProfileImage() {
      return this.user?.profile_image?.url || "";
    },
    canApplyAsMentor() {
      return (
        !this.user.roles.includes(userRoles.MENTOR) &&
        this.program.licence_end_date > new Date().toISOString() &&
        !this.applicationIsClosed(userRoles.MENTOR) && 
        this.program.multiple_roles_enabled

      );
    },
    canApplyAsMentee() {
      return (
        !this.user.roles.includes(userRoles.MENTEE) &&
        this.program.licence_end_date > new Date().toISOString() &&
        !this.applicationIsClosed(userRoles.MENTEE) && 
        this.program.multiple_roles_enabled
      );
    },
    program() {
      return this.$store.getters["participants/program"];
    },
    mentorDisplay() {
      return this.$store.getters["participants/mentorDisplay"];
    },
    menteeDisplay() {
      return this.$store.getters["participants/menteeDisplay"];
    },
    mentorApplication() {
      return this.$store.getters["participants/applicationMentor"];
    },
    menteeApplication() {
      return this.$store.getters["participants/applicationMentor"];
    },
    traineeDisplay() {
      return this.$store.getters["participants/traineeDisplay"];
    },
    isDisplayTeams() {
      if (!this.program || !this.program.integrations) {
        return false;
      }
      return this.program.integrations.find(
        (i) => i.id === integrationPlatforms.TEAMS
      )?.is_enabled;
    },
    isDisplayZoom() {
      if (!this.program || !this.program.integrations) {
        return false;
      }
      return this.program.integrations.find(
        (i) => i.id === integrationPlatforms.ZOOM
      )?.is_enabled;
    },
    isDisplaySkype() {
      if (!this.program || !this.program.integrations) {
        return false;
      }
      return this.program.integrations.find(
        (i) => i.id === integrationPlatforms.SKYPE
      )?.is_enabled;
    },
    showIntegrationsSection() {
      return this.isDisplayTeams || this.isDisplayZoom || this.isDisplaySkype;
    },
    zoomOAuthLink() {
      return `${window.location.origin}/oauth/zoom-oauth?current_url=${window.location.href}`;
    },
    menteePerMentorOptions () {
      return Array.from({length: this.mentorMatchLimit}, (_, i) => i + 1);
    },
    mentorMatchLimit() {
      const role = this.program.program_roles.find(pr => pr.role_id === userRoles.MENTOR);  
      return role ? role.match_limit : 10;
    },
  },
  async created() {
    await this.loadProfile();
    const userData = {
      ...this.user,
      ...this.parseIntegrations(this.user),
    };
    userData.user_roles = this.user.roles
    .map(role => {
      if (role === userRoles.MENTOR) {
        return {
          id: role,
          name: this.mentorDisplay,
        };
      } else if (role === userRoles.MENTEE) {
        return {
          id: role,
          name: this.menteeDisplay,
        };
      } else if (role === userRoles.TRAINEE) {
        return {
          id: role,
          name: this.traineeDisplay,
        };
      }
      return null;
    }).filter(role => role);
    this.userEdit = this.deepClone(userData);
    this.initialEditing = this.deepClone(userData);
    this.imgDataUrl = this.userProfileImage;

    if (this.program) {
      this.languageList = this.program.languages.map((lang) => ({
        id: lang.id,
        name: lang.name,
        abbreviation: lang.abbreviation,
      }));

      if (!this.userEdit.preferred_language && this.languageList.length > 0) {
        this.userEdit.preferred_language = this.languageList[0];
      }
    }
    this.emailNotifications = Boolean(
      this.user.user_program_settings.filter(
        (s) => s.email_notifications === true
      ).length > 0
    );
  },
  watch: {
    menteePerMentor(n) {
      n = Math.max(1, Math.min(this.mentorMatchLimit, Math.round(n)));
      this.menteePerMentor = n;
    }
  },
  methods: {
    applicationIsClosed(role) {
      const application = this.program.application_set.applications.filter(app => app.roles.find(r => r.id === role));
      return application[0]?.scheduled_actions?.[1]?.absolute_date &&
      new Date(application[0].scheduled_actions[1].absolute_date) < new Date();
    },
    ...mapMutations("profile", {
      RESET_PROFILE_STATE: "RESET_STATE",
    }),
    ...mapMutations("participants", {
      RESET_PARTICIPANT_STATE: "RESET_STATE",
    }),
    ...mapMutations("programs", {
      RESET_PROGRAM_STATE: "RESET_PROGRAM_STATE",
    }),
    updateSelectedUserRole(role) {
      this.userEdit.user_roles = role;
    },
    withdrawApplication(role) {
      const roleAlias = this.roleAlias(role, this.program);
      this.$refs["withdraw-confirmation-modal"]
        .confirmDelete(
          `Are you sure you want to withdraw your application? Your participation in the program as a ${roleAlias} will be withdrawn.`
        )
        .then(async (response) => {
          if (response) {
            try {
              this.isSubmitting = true;
              await applicationService.withdrawApplication(this.program.id, {
                role_id: role
              });
              this.$toast(makeSuccessToast(`Your ${roleAlias} application has been withdrawn successfully.`));
              this.$router.push({ name: 'participant-home' ,  params: { clientSlug: this.$route.params.clientSlug, programPath: this.$route.params.programPath }});
            } catch (e) {
     
              this.$toast(
                makeErrorToast(
                  e?.response?.data?.message ?  e?.response?.data?.message  : "Something went wrong. Application not withdrawn"
                ) 
              );
              this.$log.error(e);
            } finally {
              this.isSubmitting = false;
            }
          }
        });
    },
    parseIntegrations(user) {
      const integrations = {};
      if (user && user.integrations) {
        const teams = user.integrations.find(
          (integration) => integration.id === integrationPlatforms.TEAMS
        );
        const zoom = user.integrations.find(
          (integration) => integration.id === integrationPlatforms.ZOOM
        );
        const skype = user.integrations.find(
          (integration) => integration.id === integrationPlatforms.SKYPE
        );

        if (teams && teams.pivot) {
          integrations.teams = teams.pivot.data;
        }
        if (zoom && zoom.pivot) {
          integrations.zoom = zoom.pivot.access_token;
        }
        if (skype && skype.pivot) {
          integrations.skype = skype.pivot.data;
        }
      }

      return integrations;
    },
    getIntegrationEdit() {
      const integrationEdit = [];
      if (this.userEdit.teams) {
        integrationEdit.push({
          id: integrationPlatforms.TEAMS,
          data: this.userEdit.teams,
        });
      }
      if (this.userEdit.skype) {
        integrationEdit.push({
          id: integrationPlatforms.SKYPE,
          data: this.userEdit.skype,
        });
      }
      return integrationEdit;
    },
    async onValidateUser() {
      this.isSubmitting = true;
      if(this.userEdit.email !== this.user.email){
        this.showPasswordPrompt = true;
      } else {
        this.onSaveChanges();
      }
    },
    onCancel() {
      this.showPasswordPrompt = false;
      this.isSubmitting = false;
    },
    async onSaveChanges() {
      this.$bvModal
        .msgBoxConfirm(
          "Click on Update to confirm you'd like to make changes to your profile.",
          {
            title: "Update Profile",
            size: "sm",
            okVariant: "primary",
            okTitle: "Update",
            cancelTitle: "Cancel",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            try {
              let pattern =
                /^data:image\/(?:gif|png|jpeg|bmp|webp)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/g;
              this.isSubmitting = true;
              this.userEdit.user_roles.map((role) => role.id);
              const integrationEdit = this.getIntegrationEdit();
              let payload = {
                email: this.userEdit.email,
                first_name: this.userEdit.first_name,
                last_name: this.userEdit.last_name,
                phone: this.userEdit.phone,
                preferred_language_id: this.userEdit.preferred_language.id,
                programs: this.userEdit.programs,
                reverifyPassword: this.password,
                integrations: integrationEdit,
                user_program_settings: [
                  {
                    program_id: this.program.id,
                    email_notifications: this.emailNotifications,
                  },
                ],
              };

              if (this.imgDataUrl.match(pattern)) {
                payload["profile_image"] = { data_uri: this.imgDataUrl };
              }
              let response = await authService.updateUserProfile(payload);
              if (response && response.data) {
                if (this.program && this.program.id) {
                  const programId = this.program.id;
                  response.data.user_roles = response.data.user_roles.filter(
                    (r) => r.program_id === programId
                  );
                }
                this.updateProfileOnStorage(response.data);
                this.$toast(makeSuccessToast("Profile Updated Successfully."));
              }
            } catch (e) {
              const { status, data } = e.response;
              if (status === 409 || status === 403) {
                this.$toast(makeErrorToast(data.message));
              } else {
                this.$toast(makeErrorToast(e));
              }
              console.log(e);
              this.$log.error(e);
            } finally {
              this.isSubmitting = false;
              if (this.newRole) {
                await this.$store.dispatch(
                  "participants/FETCH_OWN_APPLICATION_SETS",
                  {
                    programId: this.program.id,
                    applicationSetId: this.program.application_set.id,
                    role: this.newRole,
                  }
                );
                this.routeUserToDashboard();
              }
            }
          } else {
            this.userEdit.user_roles = this.userEdit.user_roles.filter((r) => r.id !== this.newRole)
            this.isSubmitting = false;
          }
        });
      
    },
    routeUserToDashboard(role) {
      this.$router.push({
                name: "participant-home",
                params: {
                  clientSlug: this.$route.params.clientSlug,
                  programPath: this.$route.params.programPath,
                },
              });
    },
    async addNewRole(role) {
      if (this.userEdit.user_roles.filter((r) => r.id === role).length > 0) {
        this.$toast(makeErrorToast("You have already applied for this role."));
        return;
      }
  
      try{
        await usersService.selectParticipantRoles(this.program.id, {user_roles: [{role_id:  role, match_limit: this.menteePerMentor}]});
        this.$toast(makeSuccessToast("Roles Updated Successfully."));
      } catch (e) {
        const { status, data } = e.response;
        if (status === 409 || status === 403) {
          this.$toast(makeErrorToast(data.message));
        } else {
          this.$toast(makeErrorToast(e));
        }
        console.log(e);
        this.$log.error(e);
      } finally{
        await this.$store.dispatch(
          "participants/FETCH_OWN_APPLICATION_SETS",
          {
            programId: this.program.id,
            applicationSetId: this.program.application_set.id,
            role,
          }
        );
      
        this.routeUserToDashboard();
      }

    },
    deepClone(data) {
      return JSON.parse(JSON.stringify(data));
    },
    cropSuccess(imgDataUrl) {
      this.imgDataUrl = imgDataUrl;
      if (this.userEdit?.profile_image?.data_uri) {
        this.userEdit.profile_image.data_uri = imgDataUrl;
      }
    },
    showCropperDialog() {
      this.show = !this.show;
    },
    async downLoadUserPDF() {
      try {
        this.isFetching = true;
        const userId = this.user.id;
        const response = await usersService.getDownloadUserData(userId);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const time = new Date().getTime();
        const filename = `user_data_${userId}_${time}.csv`;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } catch (e) {
        console.log(e);
      } finally {
        this.isFetching = false;
      }
    },
    terminateAccountAndLogout() {
      this.$bvModal
        .msgBoxConfirm(
          "If you proceed you will be permanently terminating your account. This means all your information and account will be permanently removed from our services and will not be retrievable at all. Are you absolutely certain you would like to continue?",
          {
            title: "Permanent Account Termination!",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes, terminate my acount.",
            cancelTitle: "Cancel",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            this.terminateUserAccount().then(() => {
              this.logout();
            });
          }
        });
    },
    async terminateUserAccount() {
      try {
        const data = {
          remove_data: true,
        };
        await usersService.deleteSelf(data);
        this.$toast(makeSuccessToast("Account Terminated."));
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast(e));
      }
    },
    async logout() {
      try {
        await authService.logout();
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast());
        return;
      } finally {
        this.$router.push({ name: "participant-login" });
      }

      const redirectRoute = getAfterLogoutRoute(this.profile.role);
      if (this.isParticipantPortal && this.currentProgram) {
        redirectRoute.params = this.currentProgram.path;
      }

      // Remove userData from localStorage
      clearLocalStorage();
      this.RESET_PROFILE_STATE();
      this.RESET_PARTICIPANT_STATE();
      this.RESET_PROGRAM_STATE();
      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push(redirectRoute);
    },
    async loadProfile() {
      this.isLoading = true;
      try {
        const response = await authService.getUserProfile();
        if (response && response.data) {
          const userProfile = response.data;
          // Filter user role by program id
          if (this.program && this.program.id) {
            const programId = this.program.id;
            userProfile.user_roles = userProfile.user_roles.filter(
              (r) => r.program_id === programId
            );
          }
          this.updateProfileOnStorage(response.data);
        }
      } catch (e) {
        this.$toast(makeErrorToast("Something went wrong. Profile not loaded"));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    updateProfileOnStorage(user) {
      if (user.user_roles) {
        user["roles"] = [...user.user_roles.map((role) => role.role_id)];
        user["role"] = user.user_roles[0].role_id;
        user["programs"] = getProgramsFromUserRoles(user.user_roles);
        user["ability"] = parseAbilitiesFromPermissions({
          id: user.id,
          roles: user.roles,
          permissions: [],
        });
      }
      const userData = this.user;
      if (userData.logged_in_by) {
        user.logged_in_by = userData.logged_in_by;
      }
      this.$ability.update(user.ability);
      // localStorage.setItem("userData", JSON.stringify(user));
      this.$store.commit("profile/SET_USER_PROFILE", user);
    },
  },
  setup() {
    return {
      roleAlias,
      userStatus,
      userStatusDisplay,
      getValidationState,
      roleAlias,
    };
  },
};
</script>
<style lang="scss" scoped>
.max-image-height {
  max-height: 140px;
}
</style>
